import React, { useContext, useEffect, useRef } from "react";
import { useAuth0 } from 'components/auth0/Auth0Context';

import LoggedIn from "./LoggedIn";
import LoginCreateAccount from "components/LoginCreateAccount";
import { Panel } from "ui";
import { TransactionContext } from "components/TransactionContext";

const Wrap = props => (
    <Panel padded>
        <AccountForm {...props} />
    </Panel>
);

const AccountForm = ({ onAccountCreated, passwordOnly }) => {
    const { isAuthenticated, setRedirect } = useAuth0();
    const { banSharedEmails, showSharingWarning } = useContext(TransactionContext);
    const didInitialise = useRef(false);

    useEffect(() => {
        if (!didInitialise.current) {
            setRedirect(window.location.pathname + window.location.search);
            didInitialise.current = true;
            return;
        }
    }, [setRedirect]);

    useEffect(() => {
        if (isAuthenticated) {
            typeof onAccountCreated === 'function' && onAccountCreated();
        }

    }, [isAuthenticated, onAccountCreated]);

    if (isAuthenticated) {
        return <LoggedIn />;
    } else {
        return (
            <LoginCreateAccount 
                banSharedEmails={banSharedEmails} 
                showSharingWarning={showSharingWarning} 
                allowNewAccounts={true} 
                passwordOnly={passwordOnly}
                newAccountHeading="Let's get started"
                existingAccountHeading="Welcome back" 
            />
        );
    }
};

export default Wrap;
