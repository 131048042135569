import { TransactionContext } from 'components/TransactionContext';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Message } from 'ui';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';

export const TransactionErrors = () => {
    const error = useSelector(state => state.transaction.error);
    const { recaptcha } = useContext(TransactionContext);

    useEffect(() => {
        if (error) {
            if (recaptcha) {
                recaptcha.reset();
            }

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [error, recaptcha]);

    if (!error) { 
        return null;
    }

    return <Message error heading="Something didn't work">{error || DEFAULT_ERROR_MESSAGE}</Message>;
};
