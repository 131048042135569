export const ActionTypes = {};

export const DEFAULT_ERROR_MESSAGE =
    'An unexpected error occured, please try again. If the error persists please contact support.';

export const DEFAULT_ACCOUNT_FIELDS = [
    'first_name',
    'last_name',
    'company',
    'job',
    'job_level',
    'industry',
    'country'
];
export const ALL_ACCOUNT_FIELDS = [
    'first_name',
    'last_name',
    'company',
    'job',
    'job_level',
    'department',
    'industry',
    'address',
    'city',
    'zip',
    'region',
    'country',
    'nationality',
    'phone'
];
export const ENTERPRISE_ONBOARDING_FIELDS = [
    'first_name',
    'last_name',
    'job',
    'job_level',
    'country'
];

export const SHARED_EMAIL_BLACKLIST = [
    'team', 'accounts', 'subscribe', 'subscriptions', 'subscription', 
    'media', 'office', 'group', 'emails', 'library', 'support', 'editors', 
    'finance', 'sales', 'marketing', 'reception', 'info', 'aviation', 
    'staff', 'employees', 'news', 'administration', 'admin', 'skift', 'pr', 'mail',
    'communications', 'subs', 'mail', 'information', 'operations', 'account', 
    'hello', 'tourism', 'dailylodgingreport', 'dlr', 'skiftpro', 'pro', 'press', 'shared',
    'procurement', 'strategy', 'digital', 'hello', 'billing', 'it', 'mediarelations', 
    'admin', 'accounting'
];

export const DEFAULT_TOS_LINK = 'https://skift.com/terms/';
export const SUBSCRIPTION_TOS_LINK = 'https://skift.com/skift-subscriber-terms-of-service/';
export const PRIVACY_POLICY_LINK = 'https://skift.com/privacy/';


export const ACCEPTED_CURRENCIES = ['USD', 'GBP', 'EUR', 'CAD', 'AUD', 'SGD', 'INR', 'AED'];
export const CURRENCY_SYMBOLS = {
    GBP: '£',
    EUR: '€',
    USD: '$',
    CAD: '$',
    SGD: '$',
    AUD: '$',
    INR: '₹',
    AED: 'د.إ'
};
export const recurlyAltMethods = {
    PAYPAL: 'PAYPAL',
    APPLEPAY: 'APPLEPAY',
    VENMO: 'VENMO',
    AMAZON: 'AMAZON',
    GOOGLEPAY: 'GOOGLEPAY'
};
export const PAYPAL_CURRIENCIES = ['USD', 'EUR', 'GBP', 'AUD', 'CAD', 'SGD'];
export const TRANSACTION_TYPES = {
    EVENT: 'EVENT',
    EVENT_GROUP: 'EVENT_GROUP',
    SUBSCRIPTION: 'SUBSCRIPTION',
    MULTI_USER_SUBSCRIPTION: 'MULTI_USER_SUBSCRIPTION',
    ITEM: 'ITEM',
    EDU_CREDIT: 'EDU_CREDIT',
    ENTERPRISE_COMPANY: 'ENTERPRISE_COMPANY'
};
export const GET_ITEM_ENDPOINTS = {
    EVENT: 'public/event/details?code=',
    EVENT_GROUP: 'public/event/details?code=',
    SUBSCRIPTION: 'public/plan/details?itemId=',
    MULTI_USER_SUBSCRIPTION: 'public/plan/details?allow_multi_user=true&itemId=',
    ITEM: 'public/content/details?itemId=',
    EDU_CREDIT: 'public/edu-credits/details?itemId=',
    ENTERPRISE_COMPANY: 'public/company-by-invite-code?inviteCode='
};
export const CALCULATE_TOTAL_ENDPOINTS = {
    EVENT: 'public/event/calculate-total',
    EVENT_GROUP: null,
    SUBSCRIPTION: 'public/purchase/calculate-total/subscription',
    MULTI_USER_SUBSCRIPTION: 'public/purchase/calculate-total/multi-user-subscription',
    ITEM: 'public/purchase/calculate-total/content'
};
export const PROCESS_TRANSACTION_ENDPOINTS = {
    EVENT: 'public/event/register',
    EVENT_GROUP: 'public/event/group-tickets',
    SUBSCRIPTION: 'public/purchase/subscription',
    MULTI_USER_SUBSCRIPTION: 'public/purchase/multi-user-subscription',
    ITEM: 'public/purchase/content',
    EDU_CREDIT: 'public/edu-credits/redeem',
    ENTERPRISE_COMPANY: 'public/join-company'
};

export const MYSK_EVENT_TYPES = {
    VIEW_TRANSACTION_PAGE: 'VIEW_TRANSACTION_PAGE',
    PROMO_CODE_APPLIED: 'PROMO_CODE_APPLIED',
    TRANSACTION_FAILED: 'TRANSACTION_FAILED',
    TRANSACTION_COMPLETE: 'TRANSACTION_COMPLETE'
};

export const PERSONAL_EMAIL_DOMAINS = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'me.com',
    'icloud.com',
    'mac.com',
    'aol.com',
    'hotmail.com',
    'msn.com',
    'inbox.com'
];

window.mysk_event_types = MYSK_EVENT_TYPES;
