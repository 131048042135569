import { useTransactionTotal } from 'hooks';
import React, { createContext, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentToken, setRecaptchaToken, setError, submitTransaction, setParamsFromCookie } from 'store/features/transaction';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants';

export const TransactionContext = createContext();

let tokenize;

export const TransactionContextProvider = ({ children, ...values }) => {
    const dispatch = useDispatch();
    const [validators, setValidators] = useState([]);
    const [validatorKeys, setValidatorKeys] = useState([]);
    const [recaptcha, setRecaptcha] = useState();
    const [showMainSubmitBtn, setShowMainSubmitBtn] = useState(true);
    const processing = useSelector(state => state.transaction.processing);
    const validatorValues = useSelector(state => state.transaction);
    const { data: totalData } = useTransactionTotal();
    const { require_payment } = totalData || {};

    useEffect(() => {
        dispatch(setParamsFromCookie());
    }, [dispatch]);

    const removeValidator = useCallback(key => {
        if (validatorKeys.includes(key)) {
            const idx = validatorKeys.indexOf(key);
            validators.splice(idx, 1);
            setValidators(validators);
            validatorKeys.splice(idx, 1);
            setValidatorKeys(validatorKeys);
        }
    }, [validatorKeys, setValidatorKeys, validators, setValidators]);

    const addValidator = useCallback((key, func) => {
        // remove the item if it was already added
        removeValidator(key);

        validators.push(func);
        setValidators(validators);

        validatorKeys.push(key);
        setValidatorKeys(validatorKeys);
    }, [removeValidator, validators, setValidators, validatorKeys, setValidatorKeys ]);
    

    const handleSubmit = useCallback(async () => {
        if (validators) {
            const invalid = validators.filter(valid => {
                if (typeof valid !== 'function') {
                    return false;
                }
                
                return !valid(validatorValues);
            });

            if (invalid.length) {
                dispatch(setError('Please correct the errors below'));
                return;
            };
        }

        if (require_payment && tokenize && typeof tokenize === 'function') {
            try {
                const token = await tokenize();
                if (!token) {
                    // likely a tokenize error
                    dispatch(setError('Please correct the errors below'));
                    return;
                }
                dispatch(setPaymentToken(token));
            } catch(err) {
                dispatch(setError(DEFAULT_ERROR_MESSAGE));
                return;
            }
        }

        if (recaptcha) {
            try {
                const recaptchaToken = await recaptcha.executeAsync();
                dispatch(setRecaptchaToken(recaptchaToken))
            } catch {
                dispatch(setError('Recaptcha error'));
                return;
            }
        }

        dispatch(submitTransaction());
    }, [validators, validatorValues, dispatch, require_payment, recaptcha]);

    const setTokenize = useCallback(func => tokenize = func, []);

    const callTokenize = useCallback(() => {
        if (!tokenize || typeof tokenize !== 'function') return;

        return tokenize();
    }, []);

    const allValues = {
        onSubmit: handleSubmit,
        callTokenize,
        setTokenize,
        recaptcha,
        setRecaptcha,
        validators, 
        addValidator,
        removeValidator,
        showMainSubmitBtn,
        setShowMainSubmitBtn,
        processing,
        ...values
    };

    return (
        <TransactionContext.Provider value={allValues}>{children}</TransactionContext.Provider>
    );
};
