import React, { useContext, useEffect, useState } from 'react';

import { recurlyAltMethods } from 'utils/constants';
import { LinedHeading } from 'ui';

import RecurlyPaypal from './AltMethods/RecurlyPaypal';
import RecurlyApplePay from './AltMethods/RecurlyApplePay';
import CardForm from './CardForm';
import AltMethodPicker from './AltMethods/AltMethodPicker';
import RecurlyVenmo from './AltMethods/RecurlyVenmo';
import { TransactionContext } from 'components/TransactionContext';
import RecurlyGooglePay from './AltMethods/RecurlyGooglePay';

const MethodPicker = () => {
    const [altMethod, setAltMethod] = useState(false);
    const { setShowMainSubmitBtn } = useContext(TransactionContext);
    
    useEffect(() => {
        setShowMainSubmitBtn(!altMethod);
    }, [altMethod, setShowMainSubmitBtn])
 
    const altPaymentProps = { clear: () => setAltMethod(false) };

    switch (altMethod) {
        case recurlyAltMethods.PAYPAL:
            return <RecurlyPaypal {...altPaymentProps} />;
        case recurlyAltMethods.VENMO:
            return <RecurlyVenmo {...altPaymentProps} />;
        case recurlyAltMethods.APPLEPAY:
            return <RecurlyApplePay {...altPaymentProps} />
        case recurlyAltMethods.GOOGLEPAY:
            return <RecurlyGooglePay {...altPaymentProps} />;
        default:
            return (
                <>
                    <LinedHeading>Express checkout</LinedHeading>
                    <AltMethodPicker setAltMethod={setAltMethod} />
                    <LinedHeading>Pay with a card</LinedHeading>
                    <CardForm />
                </>
            );
    }
};

export default MethodPicker;
