export const isApplePayEnabled = () => {
    try {
        return window.ApplePaySession && window.ApplePaySession.supportsVersion(3) && window.ApplePaySession.canMakePayments();
    } catch {
        return false;
    }
};

export const isGooglePayEnabled = async () => {
    if (!window.PaymentRequest) {
        return false;
    }
  
    const methodData = [{
        supportedMethods: 'https://google.com/pay',
        data: {
            // Basic configuration for Google Pay
            environment: 'PRODUCTION',
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [{
                type: 'CARD'
            }]
        }
    }];
  
    try {
        const request = new PaymentRequest(methodData, {
            // Dummy total to validate request
            total: {
                label: 'Test Purchase',
                amount: { currency: 'USD', value: '0.01' }
            }
        });

        const result = await request.canMakePayment();
        return result || false;
    } catch (error) {
        return false;
    }
};
