import React, { useState, useEffect } from 'react';

import { PAYPAL_CURRIENCIES, recurlyAltMethods } from 'utils/constants';
import { isApplePayEnabled, isGooglePayEnabled } from 'utils/supportedAltPaymentMethods';
import { Flex, PaymentMethodButton } from 'ui';

import paypalLogo from 'assets/payments/paypal.png';
import applePayLogo from 'assets/payments/applepay.svg';
import googlepayLogo from 'assets/payments/googlepay.svg';
import { useSelector } from 'react-redux';

const AltMethodPicker = ({ setAltMethod }) => {
    const currency = useSelector(state => state.transaction.currency);

    const [googlePayEnabled, setGooglePayEnabled] = useState(false);

    useEffect(() => {
        (async () => {
            const googlePayEnabled = await isGooglePayEnabled();
            setGooglePayEnabled(googlePayEnabled);
        })();  
    }, []);

    return (
        <Flex justifyContent="center" alignItems="stretch" style={{ marginBottom: '30px' }} >
            {PAYPAL_CURRIENCIES.includes(currency) && <PaymentMethodButton onClick={() => setAltMethod(recurlyAltMethods.PAYPAL)}><img src={paypalLogo} alt="PayPal" style={{ height: '30px' }} /></PaymentMethodButton>}
            {isApplePayEnabled() && <PaymentMethodButton onClick={() => setAltMethod(recurlyAltMethods.APPLEPAY)}><img src={applePayLogo} alt="ApplePay" /></PaymentMethodButton>}
            {googlePayEnabled && <PaymentMethodButton onClick={() => setAltMethod(recurlyAltMethods.GOOGLEPAY)}><img src={googlepayLogo} alt="GooglePay" style={{ height: '30px' }} /></PaymentMethodButton>}
        </Flex>
    );
};

export default AltMethodPicker;
