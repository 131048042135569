import { TransactionContext } from 'components/TransactionContext';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { LinkButton } from 'ui';

const Wrap = styled.div`
    border: 1px solid #1b1b1b;
    padding: 10px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        height: 35px;
    }

    ${props => props.$processing && `
        opacity: 0.5;

        * {
            pointer-events: none;
        }
    `}
`;

const UsingAltMethod = ({ logo, alt, clear }) => {
    const { processing } = useContext(TransactionContext);

    return (
        <Wrap $processing={false}>
            <img src={logo} alt={alt} />
            <LinkButton onClick={clear} disabled={processing}>Change payment method</LinkButton>
        </Wrap>
    );
};

export default UsingAltMethod;
