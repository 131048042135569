import React, { useEffect, useMemo, useState } from 'react';
import { Button, LinkButton, Message } from 'ui';

import PasswordField from './PasswordField';
import EmailField from './EmailField';
import { useAuth0 } from 'components/auth0/Auth0Context';
import { useNewAccount } from 'hooks/useNewAccount';
import { STAGES } from '.';

const isValidPassword = password => {
    if (!password || !password.length) {
        return false;
    }

    return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(password);
};


const PasswordStage = ({ accountExistsResponse, email, resetEmailSent, setEmail, reset, setStage }) => {
    const [password, setPassword] = useState('');
    const [_error, setError] = useState();
    const [_processing, setProcessing] = useState(false);
    const [createAccount, { error: createAccountError, isLoading: creatingAccount }, createAccountResult] = useNewAccount();

    const { login } = useAuth0();
    
    const accountExists = useMemo(() => accountExistsResponse.account_exists, [accountExistsResponse]);

    // on mount, focus the password field
    useEffect(() => {
        const passwordField = document.getElementById('password-field');
        if (passwordField) {
            passwordField.focus();
        }
    }, []);

    const processing = useMemo(() => _processing || creatingAccount, [_processing, creatingAccount]);
    const error = useMemo(() => _error || createAccountError?.data?.error?.message, [_error, createAccountError]);
    const disabled = useMemo(() => {
        if (!isValidPassword(password) || processing) {
            return true;
        }

        return false;
    }, [password, processing]);


    const handlePasswordChange = e => {
        setError(false);
        setPassword(e.target.value);
    };

    useEffect(() => {
        if (!createAccountResult) {
            return;
        }

        (async () => {
            // sign in - this will redirect and reload the page
            await login(email, password);
        })();
    }, [createAccountResult, email, login, password]);

    const handleFormSubmit = e => {
        e.preventDefault();

        if (!isValidPassword(password)) {
            return setError('Enter a valid email address');
        }

        setProcessing(true);
        
        (async () => {
           
            try {
                if (!accountExists) {
                    createAccount({ email, password });
                    return;
                }
                
                // sign in - this will redirect and reload the page
                await login(email, password);

            } catch (err) {
                setPassword('');
                setError(err.message);
            }

            setProcessing(false);
        })();
    };

    const handleEmailChange = e => {
        setEmail(e.target.value);
        reset({ keepEmail: true });
    };
    
    return (
        <form onSubmit={handleFormSubmit}>
            <p>{accountExists ? 'Enter your password to sign in' : 'Enter a password to create your account'}</p>

            {error && <Message error>{error}</Message>}
            {resetEmailSent && <Message>We've just sent you an email to reset your password.</Message>}
            <EmailField id="email-field" value={email} onChange={handleEmailChange} />
            <PasswordField id="password-field" disabled={processing} value={password} onChange={handlePasswordChange} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button disabled={disabled} $loading={processing} black>{accountExists ? 'Sign In' : 'Create Account and Sign In'}</Button>
                {accountExists && <LinkButton onClick={() => setStage(STAGES.FORGOT_PASSWORD)} disabled={processing} style={{ marginLeft: '10px' }}>Forgot Password?</LinkButton>}
            </div>
        </form>
    );
};

export default PasswordStage;
