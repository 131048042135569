import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_TOS_LINK, PRIVACY_POLICY_LINK } from 'utils/constants';

import { useContentItem } from 'hooks';
import { 
    TransactionWrapper, 
    TransactionBody, 
    TransactionRail,
    TransactionSummary, 
    TransactionProcessing, 
    TransactionErrors,
    DisclaimerText,
    FullScreenLoader, 
    FullScreenMessage, 
    FullHeightContainer, 
    TransactionMessage,
    SubmitTransactionBtn
} from 'ui';

import { TransactionContextProvider } from 'components/TransactionContext';

import AccountForm from 'components/AccountForm';
import ProfileForm from 'components/ProfileForm';
import ScrollToActiveSection from 'components/ScrollToActiveSection';

import TransactionComplete from './TransactionComplete';
import ExclusiveEventMessage from 'components/EventRegistration/ExclusiveEventMessage';
import BraintreeBilling from 'components/BraintreeBilling';
import Quantity from './Quantity';
import SupportFooter from 'components/SupportFooter';
import Recaptcha from 'components/Recaptcha';

const Main = () => {
    const { data: item, isLoading, error: contentError } = useContentItem();
    const [accountCreated, setAccountCreated] = useState(false);
    const [profileCompleted, setProfileCompleted] = useState(false);
    const transactionComplete = useSelector(state => state.transaction.complete);

    const itemDetails = item?.details || {};

    if (isLoading) {
        return <FullScreenLoader text="Loading Event" />
    }
    
    if (!item || contentError) {
        return <FullScreenMessage error heading="Error loading event">{contentError ? (contentError?.data?.error?.message || DEFAULT_ERROR_MESSAGE) : 'Event not found'}</FullScreenMessage>;
    }

    if (!itemDetails.reg_open) {
        return (
            <TransactionMessage heading="Registration closed" showDashboardLink={false}>
                <p>Registration for this event is closed.</p>
            </TransactionMessage>
        );
    }

    if (!itemDetails.enable_group_tickets || !itemDetails.group_ticket_prices) {
        return (
            <TransactionMessage heading="Group Tickets Disabled" showDashboardLink={false}>
                <p>Group ticket purchases are not enabled for this event</p>
            </TransactionMessage>
        );
    }

    if (itemDetails.no_access_missing_exclusive_plan) {
        return <ExclusiveEventMessage />;
    }

    if (transactionComplete) {
        return <TransactionComplete />;
    }
    
    const isBillingStep = accountCreated && profileCompleted;

    return (
        <TransactionContextProvider submitBtnText="Buy Tickets" promoCodesEnabled={false}>
            <TransactionWrapper>
                <TransactionRail>
                    <TransactionSummary />
                </TransactionRail>
                <TransactionBody>
                    <TransactionErrors />
                    <AccountForm onAccountCreated={() => setAccountCreated(true)} />
                    <ProfileForm
                        inactive={!accountCreated || profileCompleted} 
                        onProfileCompleted={profile => setProfileCompleted(profile)} 
                        onClick={() => profileCompleted && setProfileCompleted(false)} 
                    />
                    <FullHeightContainer id="step-two">
                        <Quantity inactive={!isBillingStep}  />
                        <BraintreeBilling inactive={!isBillingStep} />
                        <SubmitTransactionBtn disabled={!isBillingStep} />
                        <Recaptcha />
                        <DisclaimerText>
                            <p>By purchasing, you agree to our <a href={DEFAULT_TOS_LINK} target="_blank" rel="noopener noreferrer">Terms of Service</a> and our <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                            <p>Skift, Inc. takes credit card data security very seriously. For that reason, we use <a href="https://www.braintreepayments.com/" target="_blank" rel="noopener noreferrer">Braintree</a> to securely handle credit card information. Braintree is a Validated Level 1 PCI DSS Compliant Service Provider.</p>
                        </DisclaimerText>
                        <SupportFooter />
                    </FullHeightContainer>
                </TransactionBody>
                <ScrollToActiveSection accountCreated={accountCreated} profileCompleted={profileCompleted} />
            </TransactionWrapper>
            <TransactionProcessing />
        </TransactionContextProvider>
    );
};

export default Main;
