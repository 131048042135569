import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useBraintreeToken } from "hooks/useBraintreeToken";
import { useRecurly } from '@recurly/react-recurly';
import { Message } from "ui";
import UsingAltMethod from "./UsingAltMethod";
import googlepaylogo from 'assets/payments/googlepay.svg';
import { DEFAULT_ERROR_MESSAGE } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useTransactionTotal } from "hooks";
import { TransactionContext } from "components/TransactionContext";
import { setPaymentToken } from "store/features/transaction";

const GooglePayBtnWrap = styled.div`
    margin-top: 15px;
    text-align: center;

    ${props => props.$processing && `
        opacity: 0.5;
        
        * {
            pointer-events: none;
        }
    `}
`;

const RecurlyGooglePay = ({ clear }) => {
    const dispatch = useDispatch();
    const { token: btToken } = useBraintreeToken();
    const [error, setError] = useState(false);
    const recurly = useRecurly();
    const { onSubmit, submitBtnText, processing } = useContext(TransactionContext);

    const initialized = useRef(false);
    const buttonContainer = useRef();

    const currency = useSelector(state => state.transaction.currency);
    const { data: { total } = {}, isFetching: isFetchingTotal } = useTransactionTotal();
    

    const onPaymentAuthorized = useCallback((paymentData) => {
        const { recurlyToken: token } = paymentData;
        dispatch(setPaymentToken(token.id));

        onSubmit();
    }, [dispatch, onSubmit]);

    useEffect(() => {
        if (!btToken || !recurly || initialized.current || isFetchingTotal) {
            return;
        }

        const googlePay = recurly.GooglePay({
            currency: currency,
            country: 'US',
            total: `${total}`,
            googleMerchantId: 'BCR2DN4T27ZOHT37',
            billingAddressRequired: true,
            callbacks: {
                onPaymentAuthorized,
            },
            braintree: {
                clientAuthorization: btToken
            },
            buttonOptions: {
                buttonType: submitBtnText === 'Subscribe' ? 'subscribe' : 'buy',
                buttonSizeMode: 'fill'
            }
        });
          
        // When the GooglePay instance is ready, attach the Google Pay Button to the DOM
        googlePay.on('ready', (googlePayButton) => {
            buttonContainer.current.appendChild(googlePayButton);
        });
          
          // Handle errors. These may occur at any point in the Google Pay flow
        googlePay.on('error', (err) => {
            // err.code, err.message
            console.error('Google Pay error', err);

            if (err.statusCode !== 'CANCELED') {
                setError(err);
            }
        });

        initialized.current = true;
        
    }, [recurly, btToken, buttonContainer, setError, onPaymentAuthorized, currency, total, submitBtnText, isFetchingTotal]);

    return (
        <>
            {error && <Message error>{DEFAULT_ERROR_MESSAGE}</Message>}
            <UsingAltMethod logo={googlepaylogo} alt="GooglePay" clear={clear} />
            <GooglePayBtnWrap $processing={processing} ref={buttonContainer} />
        </>
    );
};

export default RecurlyGooglePay;
